import { connect } from "react-redux";
import { setMarkerPreviewVisibility } from "../../store/actions";
import { IState } from "../../store/models";
import { AiOutlineClose } from "react-icons/ai";
import "./Preview.css";

const Preview = ({ isVisible, marker: marker, closePreview }: any) => {
	return (
		<div
			className={`preview__container preview__container--${
				isVisible && marker && "active"
			}`}
		>
			<div className="preview__close" onClick={() => closePreview()}>
				<AiOutlineClose></AiOutlineClose>
			</div>
			<div className="preview__picture">
				{marker?.properties.img && (
					<img
						src={
							marker
								? marker?.properties.img != ""
									? require("./../../assets/img/webp/" +
											marker?.properties.img +
											".webp")
									: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/1024px-Solid_white.svg.png"
								: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/1024px-Solid_white.svg.png"
						}
						// alt="Marker icon"
					></img>
				)}
				{/* {marker?.properties.img && (
					<img
						src={
							marker
								? marker?.properties.img != ""
									? require("./../../assets/img/webp/" +
											marker?.properties.img +
											".webp")
									: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/1024px-Solid_white.svg.png"
								: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/70/Solid_white.svg/1024px-Solid_white.svg.png"
						}
						// alt="Marker icon"
					></img>
				)} */}
			</div>
			<div className="preview__description__container">
				<div className="preview__title">{marker?.properties.naziv}</div>
				<div className="preview__description">{marker?.properties.opis}</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: IState) => {
	const { markers: markers } = state;
	return {
		isVisible: markers.markerPreviewsIsVisible,
		marker: markers.selectedMarker,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		closePreview: () => dispatch(setMarkerPreviewVisibility(false)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
