import React from "react";
import "./App.css";

import Header from "./components/Header/Header";
import Map from "./components/Map/Map";
import Search from "./components/Search/Search";
import Preview from "./components/Preview/Preview";
import Maphome from "./components/Maphome";
import Mapregion from "./components/Mapregion";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<Maphome />} />
					<Route path="region/:id" element={<Mapregion />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;

// return (
// 	<>
// 		<div className="nav-bar">
// 			<Header />
// 		</div>
// 		<main>
// 			<Map />
// 			<Preview />
// 		</main>
// 	</>
// );
