import { LatLngExpression } from "leaflet";
import { EtnoMarker } from "../models";

export const SET_ALL_MARKERS = "SET_ALL_MARKERS";
export const SET_SELECTED_MARKERS = "SET_SELECTED_MARKERS";
export const SET_MARKERS_PREVIEW_VISIBILITY = "SET_MARKERS_PREVIEW_VISIBILITY";
export const SET_MARKERS_FORM_VISIBILITY = "SET_MARKERS_FORM_VISIBILITY";
export const SET_PRE_MARKERS_LOCATION = "SET_PRE_MARKERS_LOCATION";
export const ADD_NEW_MARKERS = "ADD_NEW_MARKERS";

export const setAllMarkers = (markers: EtnoMarker[]) => ({
	type: SET_ALL_MARKERS,
	payload: markers,
});

export const setSelectedMarker = (marker: EtnoMarker) => ({
	type: SET_SELECTED_MARKERS,
	payload: marker,
});

export const setMarkerPreviewVisibility = (show: boolean) => ({
	type: SET_MARKERS_PREVIEW_VISIBILITY,
	payload: show,
});

export const setMarkerFormVisibility = (show: boolean) => ({
	type: SET_MARKERS_FORM_VISIBILITY,
	payload: show,
});

export const setPreMarkerLocation = (payload: LatLngExpression) => ({
	type: SET_PRE_MARKERS_LOCATION,
	payload,
});

export const addNewPlace = (payload: EtnoMarker) => ({
	type: ADD_NEW_MARKERS,
	payload,
});
