import { LatLngExpression } from "leaflet";
import { EtnoRegion } from "../models";

export const SET_ALL_REGIONS = "SET_ALL_REGIONS";
export const SET_SELECTED_REGION = "SET_SELECTED_REGION";
export const SET_REGION_PREVIEW_VISIBILITY = "SET_REGION_PREVIEW_VISIBILITY";
export const SET_PRE_REGION_LOCATION = "SET_PRE_REGION_LOCATION";
export const ADD_NEW_REGION = "ADD_NEW_REGION";

export const setAllRegion = (regions: EtnoRegion[]) => ({
	type: SET_ALL_REGIONS,
	payload: regions,
});

export const setSelectedRegion = (region: EtnoRegion) => ({
	type: SET_SELECTED_REGION,
	payload: region,
});

export const setRegionPreviewVisibility = (show: boolean) => ({
	type: SET_REGION_PREVIEW_VISIBILITY,
	payload: show,
});

export const setPreRegionLocation = (payload: LatLngExpression) => ({
	type: SET_PRE_REGION_LOCATION,
	payload,
});

export const addNewRegion = (payload: EtnoRegion) => ({
	type: ADD_NEW_REGION,
	payload,
});
