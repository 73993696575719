import { connect } from "react-redux";
import { setSearchVisibility } from "../../store/actions";
import { IState } from "../../store/models";
import "./Header.css";
import { CgPlayListSearch } from "react-icons/cg";
import logo from "./../../assets/logo.webp";

const Header = ({ searchIsVisible, setSearchVisibility }: any) => {
	return (
		<>
			<div className="header__container">
				{/* <CgPlayListSearch
        style={{
          fontSize: "3rem",
          verticalAlign: "middle",
          position: "absolute",
          left: "1rem",
          top: "10px",
        }}
        onClick={() => setSearchVisibility(!searchIsVisible)}
      ></CgPlayListSearch> */}
				<img
					src={logo}
					// width="30"
					// height="6"
					className="d-inline-block align-top"
					alt="React Bootstrap logo"
					style={{
						// fontSize: "3rem",
						verticalAlign: "middle",
						position: "relative",
						left: "1rem",
						// top: "10px",
						// height: "60",
					}}
				/>
			</div>
			<hr />
		</>
	);
};

const mapStateToProps = (state: IState) => {
	const { search } = state;
	return search;
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		setSearchVisibility: (payload: boolean) =>
			dispatch(setSearchVisibility(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
