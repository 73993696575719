import {
	SET_ALL_MARKERS,
	SET_SELECTED_MARKERS,
	SET_MARKERS_PREVIEW_VISIBILITY,
	SET_MARKERS_FORM_VISIBILITY,
	SET_PRE_MARKERS_LOCATION,
	ADD_NEW_MARKERS,
} from "../actions";
import { EtnoMarkerState } from "../models";
import { markersData } from "../../markerData";
import { LatLngExpression } from "leaflet";

const initialState: EtnoMarkerState = {
	markers: markersData,
	selectedMarker: null,
	markerPreviewsIsVisible: false,
	markerFormIsVisible: false,
	preMarkerPosition: null as unknown as LatLngExpression,
};

const productsReducer = (
	state: EtnoMarkerState = initialState,
	action: { type: string; payload: any }
): EtnoMarkerState => {
	switch (action.type) {
		case SET_ALL_MARKERS: {
			return { ...state, markers: action.payload };
		}
		case SET_SELECTED_MARKERS: {
			return { ...state, selectedMarker: action.payload };
		}
		case SET_MARKERS_PREVIEW_VISIBILITY: {
			return { ...state, markerPreviewsIsVisible: action.payload };
		}
		case SET_MARKERS_FORM_VISIBILITY: {
			return { ...state, markerFormIsVisible: action.payload };
		}
		case SET_PRE_MARKERS_LOCATION: {
			return { ...state, preMarkerPosition: action.payload };
		}
		case ADD_NEW_MARKERS: {
			return { ...state, markers: [...state.markers, action.payload] };
		}
		default: {
			return state;
		}
	}
};

export default productsReducer;
