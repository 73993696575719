import {
	SET_ALL_REGIONS,
	SET_SELECTED_REGION,
	SET_REGION_PREVIEW_VISIBILITY,
	SET_PRE_REGION_LOCATION,
	ADD_NEW_REGION,
} from "../actions";
import { EtnoRegionState } from "../models";
import { regionsData } from "../../regionData";
import { LatLngExpression } from "leaflet";

const initialState: EtnoRegionState = {
	regions: regionsData,
	selectedRegion: null,
	regionPreviewsIsVisible: false,
	preRegionPosition: null as unknown as LatLngExpression,
};

const productsReducer = (
	state: EtnoRegionState = initialState,
	action: { type: string; payload: any }
): EtnoRegionState => {
	switch (action.type) {
		case SET_ALL_REGIONS: {
			return { ...state, regions: action.payload };
		}
		case SET_SELECTED_REGION: {
			return { ...state, selectedRegion: action.payload };
		}
		case SET_REGION_PREVIEW_VISIBILITY: {
			return { ...state, regionPreviewsIsVisible: action.payload };
		}
		case SET_PRE_REGION_LOCATION: {
			return { ...state, preRegionPosition: action.payload };
		}
		case ADD_NEW_REGION: {
			return { ...state, regions: [...state.regions, action.payload] };
		}
		default: {
			return state;
		}
	}
};

export default productsReducer;
