import React from "react";
// import "./App.css";

import Header from "./Header/Header";
import Map from "./Map/Map";
import Preview from "./Preview/Preview";

function Maphome() {
	return (
		<>
			<div className="nav-bar">
				<Header />
			</div>
			<main>
				<Map />
				<Preview />
			</main>
		</>
	);
}

export default Maphome;
