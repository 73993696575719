import React from "react";
import { useParams } from "react-router-dom";
// import "../App.css";

import Header from "./Header/Header";
import Map from "./Map/Map";
import Preview from "./Preview/Preview";
// import Form from "./components/Form/Form";

function Mapregion() {
	return (
		<>
			<div className="nav-bar">
				<Header />
			</div>
			<main>
				<Map />
				<Preview />
			</main>
		</>
	);
}

export default Mapregion;
