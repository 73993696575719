import { useEffect, useState } from "react";
import L, { LatLngExpression } from "leaflet";
import {
	MapContainer,
	TileLayer,
	Marker,
	Polygon,
	useMapEvents,
	useMap,
} from "react-leaflet";
import { connect } from "react-redux";
import {
	setMarkerPreviewVisibility,
	setSelectedMarker,
} from "../../store/actions";
import { IState, EtnoMarker, EtnoRegion } from "../../store/models";
import pointerIconPin from "../../assets/pin_white_30px.webp";
import "./Map.css";
import { useParams } from "react-router-dom";

const Map = ({
	isVisibleMarker,
	markers,
	regions,
	selectedMarker,
	togglePreview,
	setMarkerForPreview,
}: any) => {
	const defaultPosition: LatLngExpression = [44.146, 20.922]; // Paris position

	const { id } = useParams();
	const [firstLoad, setFirstLoad] = useState(true);
	const [flyToRegion, setFlyToRegion] = useState<EtnoRegion>();

	useEffect(() => {
		if (firstLoad)
			if (id) {
				var region: EtnoRegion = regions.find(
					(element: EtnoRegion) => element.properties.id === id
				);

				setFlyToRegion(region);
				setTimeout(() => {
					showMarkerPreview(region);
				}, 500);
			}
	}, []);
	// TODO image placeholder
	function FlyToBounds() {
		const map = useMap();

		useEffect(() => {
			if (firstLoad)
				if (flyToRegion)
					map.flyToBounds(
						flyToRegion.geometry.coordinates.map((coordinates) => [
							coordinates[1],
							coordinates[0],
						]),
						{
							paddingTopLeft: [200, 200],
							paddingBottomRight: [200, 200],
						}
					);
		}, []);

		setFirstLoad(false);

		return <></>;
	}
	function ClosePreviewOnMapClick() {
		const map = useMapEvents({
			click(e) {
				if (isVisibleMarker) {
					togglePreview(false);
					setMarkerForPreview(null);
				}
			},
		});

		return <></>;
	}

	function LocationMarker(marker: EtnoMarker) {
		const [position, setPosition] = useState({ latitude: 0, longitude: 0 });

		const map = useMapEvents({});

		return (
			<Marker
				icon={pointerIcon}
				key={marker.properties.img}
				position={[
					marker.geometry.coordinates[1],
					marker.geometry.coordinates[0],
				]}
				eventHandlers={{
					click: () => {
						map.flyTo(
							[marker.geometry.coordinates[1], marker.geometry.coordinates[0]],
							10,
							{ duration: 0.45 }
						);

						// setTimeout(() => {
						showMarkerPreview(marker);
						// }, 500);
					},
				}}
			></Marker>
		);
	}
	function LocationRegion(region: EtnoRegion) {
		const [position, setPosition] = useState({ latitude: 0, longitude: 0 });

		const map = useMapEvents({});

		return (
			<Polygon
				positions={region.geometry.coordinates.map((coordinates) => [
					coordinates[1],
					coordinates[0],
				])}
				key={region.properties.id}
				pathOptions={{
					color: "#f0683d",
					fillColor: "#ffffff",
				}}
				eventHandlers={{
					click: () => {
						map.flyToBounds(
							region.geometry.coordinates.map((coordinates) => [
								coordinates[1],
								coordinates[0],
							]),
							{
								paddingTopLeft: [200, 200],
								paddingBottomRight: [200, 200],
								duration: 0.45,
							}
						);
						// setFlyToRegion(region);
						// setTimeout(() => {
						showMarkerPreview(region);
						// }, 500);
					},
				}}
			></Polygon>
		);
	}

	const showMarkerPreview = (marker: any) => {
		// map.flyTo([21.351242065429684, 44.02343405525542], map.getZoom());

		if (isVisibleMarker) {
			togglePreview(false);
			setMarkerForPreview(null);
		}

		if (selectedMarker?.properties.naziv == marker.properties.naziv) {
			showMarker(marker);
		} else
			setTimeout(() => {
				showMarker(marker);
			}, 500);
	};

	const showMarker = (marker: any) => {
		setMarkerForPreview(marker);
		togglePreview(true);
	};

	const pointerIcon = new L.Icon({
		iconUrl: pointerIconPin,
		iconSize: [30, 38], // size of the icon
		iconAnchor: [15, 38], // changed marker icon position
	});

	return (
		<div
			className={`map__container
			map__container--${isVisibleMarker && selectedMarker && "active"}`}
		>
			<MapContainer
				center={defaultPosition}
				zoom={7.49}
				maxZoom={15.0}
				style={{ height: "100vh" }}
			>
				<TileLayer
					// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url={
						"https://api.mapbox.com/styles/v1/dinomaskot/ckwxzyfh764x215ns3n6jswdo/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGlub21hc2tvdCIsImEiOiJja3dreDJseW0wNnh6MnVsbnNvYWh0ajY5In0.Nu_UgthjtEPSaOTXHP5D5A"
					}
				/>

				{markers.map((marker: EtnoMarker) => (
					<LocationMarker
						properties={{
							naziv: marker.properties.naziv,
							opis: marker.properties.opis,
							img: marker.properties.img,
							other: "",
						}}
						geometry={{
							coordinates: marker.geometry.coordinates,
						}}
					/>
				))}
				{regions.map((region: EtnoRegion) => (
					<LocationRegion
						properties={region.properties}
						geometry={region.geometry}
					/>
				))}
				<FlyToBounds />
				<ClosePreviewOnMapClick />
			</MapContainer>
		</div>
	);
};

const mapStateToProps = (state: IState) => {
	const { markers, regions } = state;
	return {
		isVisibleMarker: markers.markerPreviewsIsVisible,
		markers: markers.markers,
		selectedMarker: markers.selectedMarker,
		regions: regions.regions,
		selectedRegion: markers.selectedMarker,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		togglePreview: (payload: boolean) =>
			dispatch(setMarkerPreviewVisibility(payload)),
		setMarkerForPreview: (payload: any) => dispatch(setSelectedMarker(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

function LatLngTuplet(LatLngTuplet: any): [any, any] {
	throw new Error("Function not implemented.");
}
